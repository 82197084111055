<template>
  <div class="wrapper">
    <header class="move-up">
      <div>
        <h2>Najem - <span>Witaj w usłudze zarządzania najmem</span></h2>
      </div>
    </header>
    <div class="cols">
      <base-card :columns="12" class="">
        <template #default>
          <apartments-list
            :apartmentsList="apartmentsFiltered"
            :text="false"
          ></apartments-list>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import ApartmentsList from "../components/apartments/ApartmentsList.vue";
import BaseCard from "../components/base/BaseCard.vue";

import { mapGetters } from "vuex";

export default {
  components: { ApartmentsList, BaseCard },
  props: ["minSurface", "maxSurface", "roomCount", "districtSelected"],
  data() {
    return {};
  },
  computed: {
    apartmentsFiltered() {
      if (
        this.minSurface ||
        this.maxSurface ||
        this.roomCount ||
        this.districtSelected
      ) {
        return this.getAllApartments.filter((ap) => {
          return (
            parseInt(ap.surface) <= parseInt(this.maxSurface) &&
            parseInt(ap.surface) >= parseInt(this.minSurface) &&
            parseInt(ap.rooms) === parseInt(this.roomCount) &&
            ap.district === this.districtSelected
          );
        });
      }
      return this.getAllApartments;
    },
    ...mapGetters(["getAllApartments"]),
  },
};
</script>

<style scoped lang="scss">
@import "~@/_base.scss";

header {
  text-shadow: $multi;
  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 1.75em;
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: white;
    }
  }
}
.cols {
  display: flex;
  justify-content: space-between;
}
.pagination {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: $baseSize * 1.3;
  border-top: $black;
  div {
    &:first-child {
      span {
        &:first-child {
          font-weight: bold;
        }
        margin-right: 1rem;
      }
    }
  }
}
</style>